<template>
  <div class="app-main-scroll-wrap content">
    <div style="display: flex; justify-content: end;align-items: center;">
      <div style="display: flex; align-items: center;" v-if="active !== 2">
        <el-button @click="goBack" size="small" class="back_btn">
          <img src="@/assets/back.png" alt="">
        </el-button>
        <div style="font-weight: 600;font-size: 18px;margin: 0 10px;">{{ active ? '返回购物车' : '返回选购' }}</div>
      </div>
    </div>
    <el-steps :active="active" finish-status="success" simple style="margin-top: 20px">
      <el-step title="1、购物车"></el-step>
      <el-step title="2、填写结算信息"></el-step>
      <el-step title="3、提交订单并支付"></el-step>
    </el-steps>
    <carts v-if="active === 0" @goNext="goNext"></carts>
    <settlementInformation v-if="active === 1" @getBatch_sn="getBatch_sn" @goNext="goNext"></settlementInformation>
    <submitOrder v-if="active === 2 && batch_sn" :batch_sn="batch_sn"></submitOrder>
  </div>
</template>

<script>
import carts from './components/carts'
import settlementInformation from './components/settlementInformation'
import submitOrder from './components/submitOrder'
export default {
  name: 'ManagerOneClickDeliveryShopCarts',
  components: { carts, settlementInformation, submitOrder },
  data () {
    return {
      active: 0,
      batch_sn: '20231208000019'
    };
  },

  mounted () {
  },

  methods: {
    getBatch_sn (e) {
      this.batch_sn = e
    },
    goBack () {
      if (this.active) return this.active--
      this.$router.go(-1)
    },
    goNext (e) {
      this.active++
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #fff;
  min-height: 90vh;
}

.toolbar-title {
  height: 25.5px;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  padding-left: 4px;
  margin-right: 12px;

  &::before {
    content: ' ';
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #1A43A9;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translate(0, -50%);
  }
}

.back_btn {
  width: 36px;
  height: 36px;
  background-color: #1A43A9;
  border-radius: 50%;

  img {
    width: 10px;
    height: 20px;
    position: relative;
    top: -2px;
    left: -4px;
  }
}
</style>