<template>
  <div>
    <div style="display: flex;justify-content: space-between;align-items: flex-end; margin: 10px 0;">
      <div style=" font-weight: 600;">商品列表</div>
      <el-button style="padding: 10px 30px;" type="danger" size="small" @click="_ => deleteCarts()">批量删除</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%" header-cell-class-name="headerStyle" ref="multipleTable"
      :span-method="arraySpanMethod" :cell-class-name="cellStyle" @select="handleSelectionChange"
      @select-all="handleSelectionChangeAll">
      <el-table-column type="selection" :selectable="selectable" />
      <el-table-column label="商品图片" width="126" class-name="goods-cover-wrapper">
        <template slot-scope="{row}">
          <el-popover placement="right" trigger="hover">
            <img :src="row.thumbnail" alt="" style="width: 300px">
            <div slot="reference" style="width: 90px;height: 90px;" @click="openGoodsDetail(row)"
              :class="row.valid_status ? '' : 'invalid'">
              <img :src="row.thumbnail" class="goods-cover" alt="" />
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="商品名称">
        <template slot-scope="{row}">
          <!-- <div class="ellipsis-multiline" style="color: #a341ff;" @click="openGoodsDetail(row)">
            {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
          </div> -->
          <!-- tips悬浮提示 -->
          <el-tooltip placement="top" v-model="row.showTooltip" :open-delay="500" effect="dark"
            :disabled="!row.showTooltip">
            <div slot="content">{{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</div>
            <div @mouseenter="showTips($event, row)" @click="openGoodsDetail(row)" class='ellipsis-multiline'>
              {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
            </div>
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column prop="num" label="数量" width="191">
        <template slot-scope="scope">
          <div style="position: relative;height: 100%;">
            <el-input-number size="mini" :min="1" :max="9999" v-model="scope.row.num"
              :disabled="scope.row.valid_status === 0"
              @change="(num, oldNum) => putCarts(num, oldNum, scope.row)"></el-input-number>
            <div style=" position: absolute; top: 2em; left: 0;width: 100%; display: flex; justify-content: center;">
              <div v-if="scope.row.insufficientStock" style="width: 130px;text-align: left;">
                <span style="color: #c08435;">库存不足</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="单价" width="191">
        <template slot-scope="scope">
          {{ scope.row.price || '————' }}
        </template>
      </el-table-column>
      <el-table-column prop="goods_money" label="金额小计" width="191">
        <template slot-scope="scope">
          {{ scope.row.goods_money || '————' }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="191">
        <template slot-scope="scope">
          <div v-if="scope.row.err" style="display: flex;justify-content: space-between;">
            <div>以下商品已下架或删除</div>
            <el-button size="mini" type="danger" @click="clearInvalidProduct">一键清空失效商品</el-button>
          </div>
          <el-button v-else size="mini" type="danger" @click="deleteCarts(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="settlement">
      <div style="display: flex;">
        <div style="flex: 1;">
          <div style="float: right;font-weight: 700;line-height: 50px;">
            <span>已选商品金额：</span>
            <span style="color: #b8741a;font-size: 20px;margin: 0 16px 0 0;">{{ total }}</span>
          </div>
        </div>
        <div class="button" @click="submit">去结算</div>
      </div>
    </div>
    <el-dialog-x-dialog title="提示" :visible.sync="editVisible" class="dialog">
      <div style=" padding: 20px 40px;">
        <div>
          购物车中已选商品存在已下架或删除商品，当前页面将刷新，请重新确认已选商品。
        </div>
        <div style=" text-align: center; margin: 20px 0;margin-bottom: 0;">
          <el-button type="primary" size="small" @click="init">知道了</el-button>
        </div>
      </div>
    </el-dialog-x-dialog>
    <el-dialog custom-class="goodsclassify" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body width="1150px">
      <div slot="title" class="goods-picker-title">
        <span></span>
        商品详情
      </div>
      <goodsPurchaseclassify v-if="dialogVisible" :formData="goodsclassifyData" :cartNum="cartNum">
      </goodsPurchaseclassify>
    </el-dialog>
  </div>
</template>

<script>
import { getCarts, putCarts, deleteCarts, checkedCarts, checkCartGoodsStock } from '@/api/shopGoodsPurchase'
import goodsPurchaseclassify from "@/components/GoodsClassify/goodsPurchaseclassify";
const cellStyle = ({ row, column, rowIndex, columnIndex }) => columnIndex === 2 ? '' : 'cellStyle'

// 高精度乘法
const multiply = (arg1, arg2) => {
  let m = 0
  let s1 = arg1.toString()
  let s2 = arg2.toString()
  try {
    m += s1.split(".")[1].length
  } catch (e) { }
  try {
    m += s2.split(".")[1].length
  } catch (e) { }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}
const add = (arg1, arg2) => {
  var r1, r2, max;
  try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
  max = Math.pow(10, Math.max(r1, r2))
  return (multiply(arg1, max) + multiply(arg2, max)) / max
}
export default {
  name: 'ManagerOneClickDeliveryCarts',
  components: { goodsPurchaseclassify },
  data () {
    return {
      tableData: [],
      idsList: [],
      firstEntry: true,
      editVisible: false,
      dialogVisible: false,
      goodsclassifyData: {},
    };
  },
  computed: {
    total () {
      return this.tableData.reduce((total, item) => {
        if (item.goods_money && this.idsList.includes(item.sku_id)) return add(total, multiply(item.price, item.num))
        return total
      }, 0)
    }

  },
  async mounted () {
    this.init()
  },

  methods: {
    showTips (obj, row) {
      /*obj为鼠标移入时的事件对象*/

      /*currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
      let TemporaryTag = document.createElement('span');
      TemporaryTag.innerText = row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
      TemporaryTag.className = 'getTextWidth';
      document.querySelector('body').appendChild(TemporaryTag);
      let currentWidth = document.querySelector('.getTextWidth').offsetWidth;
      document.querySelector('.getTextWidth').remove();

      /*cellWidth为表格容器的宽度*/
      const cellWidth = obj.target.offsetWidth

      /*当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
      currentWidth <= (2 * cellWidth) ? row.showTooltip = false : row.showTooltip = true
    },

    openGoodsDetail (row) {
      this.dialogVisible = true
      console.log(row);
      this.goodsclassifyData = {
        from: "",
        goods_id: row.goods_id,
        good_sn: row.good_sn,
        revise_price: row.revise_price,
        shop_profit: row.shop_profit,
        islogin: this.MixinIsFormEnterprise,//是否单独登录
        distribution_rate: row.distribution_rate,
        sku_id: row.sku_id,
        addCarts: this.addCarts,
      }
    },
    async init () {
      const res = await this.getCarts()
      await this.toggleSelection()
      this.firstEntry = false
      this.editVisible = false
    },
    selectable (row) {
      return !!row.valid_status
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.err) {
        if (columnIndex === 6) return [1, 7]
        return [0, 0]
      }
    },
    /**获取购物车列表 */
    async getCarts () {
      const res = await getCarts()
      if (!res?.data?.list) this.cartNum = 0
      else this.cartNum = res?.data?.list?.[0]?.all_list?.reduce((a, b) => a + b.num, 0) ?? 0
      const invalid_list_id = res?.data?.list?.[0]?.invalid_list?.map(item => item.sku_id) ?? []
      const list = res?.data?.list?.[0]?.all_list.filter(item => !invalid_list_id.includes(item.sku_id)) ?? []
      const invalid_list = res?.data?.list?.[0]?.invalid_list ?? []
      if (invalid_list.length) list.push({ err: true }, ...invalid_list)

      // list.forEach((item, index) => {
      //   if (index === 1) list.splice(index, 0, { err: true })
      // })
      this.tableData = list
    },
    /**删除购物车 */
    async deleteCarts (row) {
      let sku_ids
      try {
        console.log(this.idsList);
        if (!row && !this.idsList.length) return this.$message.error('请勾选商品')
        await this.$confirm('确定从购物车中删除所选商品？', '提示', { type: 'warning' })
        if (row) sku_ids = [row.sku_id]
        else sku_ids = this.idsList
        const res = await deleteCarts({ sku_ids }) // 删除购物车
        await this.getCarts()
        await this.toggleSelection()
      } catch (error) {

      }
    },
    /**一键清空失效商品 */
    async clearInvalidProduct () {
      try {
        await this.$confirm('确定从购物车中删除失效商品？', '提示', { type: 'warning' })
        const sku_ids = this.tableData.filter(v => !v.valid_status).map(item => item.sku_id)
        const res = await deleteCarts({ sku_ids }) // 删除购物车
        await this.getCarts()
        await this.toggleSelection()
      } catch (error) {

      }
    },
    /**修改购物车 */
    async putCarts (num, oldNum, row) {
      try {
        // row.num = num
        await putCarts({ sku_id: row.sku_id, num })
        await this.getCarts()
        await this.toggleSelection()
      } catch (error) {
        row.num = oldNum
      }
    },
    /**去结算 */
    async submit () {
      if (!await this.checkCartGoodsStock()) return
      this.handleCheckCarts()
      this.$emit('goNext')
    },
    async checkCartGoodsStock () {
      const res = await checkCartGoodsStock()
      if (!res || !res.data || !Array.isArray(res.data) || !res.data.length) return
      // 1 正常 10 下架或删除 20 库存不足
      // 下架或删除
      const delistingOrDeleting = res.data.some(item => item.valid_status === 10)
      if (delistingOrDeleting) {
        this.editVisible = true
        return false
      }
      // 库存不足
      let insufficientStock = false
      res.data.forEach(item => {
        if (item.valid_status === 20) {
          const tabItem = this.tableData.find(tabItem => item.sku_id === tabItem.sku_id)
          insufficientStock = true
          this.$set(tabItem, 'insufficientStock', true)
        }
      })
      if (insufficientStock) return false
      return true
    },
    handleSelectionChange (val, row) {
      const targrt = val.find(item => item === row)
      let ids = []
      val.forEach(item => {
        if (item.sku_id) ids.push(item.sku_id)
      })
      if (ids.length - this.idsList.length === 0) {

      }
      this.idsList = ids
      if (!this.firstEntry) {
        if (targrt) checkedCarts({ sku_ids: [targrt.sku_id], checked: 1 /**0取消 1勾选 */ })
        else checkedCarts({ sku_ids: [row.sku_id], checked: 0 /**0取消 1勾选 */ })
      }

    },
    handleSelectionChangeAll (val) {
      // 全选
      if (val.length) {
        let ids = []
        val.forEach(item => {
          if (item.sku_id) ids.push(item.sku_id)
        })
        if (ids.length - this.idsList.length === 0) {

        }
        this.idsList = ids
        this.handleCheckCarts()
      } else {
        // 取消全选
        this.idsList = []
        this.handleCheckCarts()
      }
    },
    async handleCheckCarts () {
      const ids = this.tableData.filter(v => v.sku_id).map(item => item.sku_id)
      const selected = [...this.idsList]
      const notSelected = ids.filter(id => !selected.includes(id))
      if (notSelected.length) await checkedCarts({ sku_ids: notSelected, checked: 0 /**0取消 1勾选 */ })
      if (selected.length) await checkedCarts({ sku_ids: selected, checked: 1 /**0取消 1勾选 */ })
    },
    toggleSelection () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const multipleTable = this.$refs.multipleTable
          this.tableData.forEach(row => {
            if (row.checked) multipleTable.toggleRowSelection(row), this.idsList.push(row.sku_id);
          })
          resolve()
        }, 50)
      })
    },
    cellStyle

  },
};
</script>

<style lang="scss">
.dialog {
  .el-dialog {
    width: 30% !important;
  }

}

.headerStyle {
  font-weight: 400;

  &>div {
    text-align: center;
    color: #333333;
  }

}

.cellStyle {
  &>div {
    text-align: center;
    overflow: visible !important;
  }
}
</style>
<style lang="scss" scoped>
.goods-cover-wrapper {
  padding: 0;

  .cell {
    padding: 0;
    text-align: center;

    span,
    a {
      display: inline-block;
    }
  }

  .goods-cover {
    width: 52px;
    height: 52px;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }
}

// 显示遮罩
.invalid {
  position: relative;

  &::after {
    content: '商品已下架或删除';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 6px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 1.5;
    font-weight: 400;
  }
}

.settlement {
  height: 50px;
  border: 1px solid #ebeef5;
  border-top: 0;

  .button {
    width: 100px;
    height: 100%;
    line-height: 50px;
    text-align: center;
    background-color: #b8741a;
    color: #fff;
    cursor: pointer;
  }
}
</style>